@import "~bulma/bulma.sass";

.select {
  select {

    // The select element is set to required
    // as long as the selected options value
    // is empty the element is not valid.
    &:invalid {
      color: $input-placeholder-color;
    }

    // Styling for browsers which do support
    // styling select option elements directly
    [disabled] {
      color: $input-placeholder-color;
    }

    option {
      color: black;
    }
  }
}